import * as React from 'react'

import { useTheme } from '@thg-commerce/enterprise-pages/src/Product/theme'
import { Option } from '@thg-commerce/gravity-elements/Option'
import {
  Option as OptionProps,
  OptionType,
  ProductOptionsProps,
} from '@thg-commerce/gravity-elements/Option/Option'
import { spacing } from '@thg-commerce/gravity-theme'

import { SkeletonProductOption } from './styles'

export const SimpleProductOptions = (props: ProductOptionsProps) => {
  const theme = useTheme()

  const textStyle =
    theme.pageTheme?.productOptions?.variantSelector?.textStyle?.textTransform

  const Options = props.options.map((option: OptionProps, index: number) => {
    return (
      <div
        style={{
          width: '100%',
          gap: spacing(1),
          display: 'flex',
          flexDirection: 'column',
          order:
            option.order?.length === props.options.length
              ? option.order?.[index]
              : index,
        }}
        key={index}
      >
        <Option
          option={
            props.forceDropDowns
              ? { ...option, type: OptionType.Dropdown }
              : option
          }
          chevronDown={theme.icons.chevronDown}
          {...props}
          index={index}
          textStyle={textStyle ?? 'none'}
        />
      </div>
    )
  })

  if (!Options.length) {
    return null
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {props.loading ? <SkeletonProductOption /> : Options}
    </div>
  )
}
