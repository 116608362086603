import * as React from 'react'

import { useTheme } from '@thg-commerce/gravity-patterns/theme'

import { IconPosition } from '../CustomDropdown/CustomDropdown'
import { LabelText } from '../FormItem/Label'
import { ImageSwatch, RadioGroup, SquaredRadioInput, Swatch } from '../index'
import { SwatchShape } from '../Swatch/types'

import { StyledDropdown } from './styles'

interface ChoiceIcon {
  fill: string
  path: string
}

// @TODO: REBUILD-8977: consistent casing
export enum OptionType {
  Size = 'Size',
  Radio = 'RADIO',
  Dropdown = 'DROPDOWN',
  Image = 'IMAGE',
  Swatch = 'SWATCH',
}

interface Choice {
  key: string
  title: string
  disabled: boolean
  selected: boolean
  useDisabledStyling: boolean
  icon?: ChoiceIcon | null
  image?: string | null
  colour?: string | null
  attribute?: boolean | null
  displayText?: string
}

export interface Option {
  type: OptionType
  label: string
  key: string
  choices: Choice[]
  attribute?: boolean | null
  selectedChoice?: string | boolean
  placeholder?: string
  thumbnailImage?: string | null
  order?: number[]
  localizedKey?: string
}

export interface SelectedOptions {
  [key: string]: string
}
export interface ProductOptionsProps {
  options: Option[]
  i18nText: {
    swatch: {
      unavailableText: string
      closeButtonText: string
      showLessButtonText: string
      showMoreButtonText: string
    }
    imageSwatch: {
      showButtonText: string
      showMoreButtonText: string
      showLessButtonText: string
    }
    wishlist?: {
      unavailableText: string
    }
  }
  onOptionChange: (key: string, value: string) => void
  onQuantityChange?: (quantity: number) => void
  onDropdownClick?: () => void
  onColourSwatchClick?: () => void
  selectedOptions?: SelectedOptions
  hideProductOptionsSwatch?: boolean
  loading?: boolean
  forceDropDowns?: boolean
  hideDropDownsLabel?: boolean
  isWishlistOption?: boolean
  required?: boolean
  swatchShape?: SwatchShape
  notifyWhenInStockInDropdown?: boolean
  swatch?: {
    show: boolean
  }
  labelCustomComponent?: React.ReactNode
  dropdownWidth?: string
  maxDropdownHeight?: string
  showOnlyTwoRows?: boolean
  displayColoursAsTextOnly?: boolean
  customErrorMessage?: {
    displayError?: boolean
    errorMessage?: string
  }
  showAllImageSwatches?: boolean
  chevronDown?: {
    svgPath: string
    viewBox: string
    width: string
    height: string
  }
  textStyle: string
}

const DropdownComponent = (props) => {
  const { choicesWithColour } = props

  const handleDropdownChange = (event) => {
    props.onOptionChange(props.option.key, event.key.toString() || '')
    props.onDropdownClick?.()
  }

  const renderLabelCustomComponent = () => {
    if (!props.option.attribute) return null
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        {props.labelCustomComponent}
      </div>
    )
  }

  return (
    <StyledDropdown
      data-testid="dropdown-option"
      iconOverride={props.chevronDown}
      label={props.option.localizedKey || props.option.label}
      textStyle={props.textStyle}
      removeLabelMargin={true}
      labelCustomComponent={renderLabelCustomComponent()}
      labelHidden={props.hideDropDownsLabel || false}
      required={props.required ?? true}
      aria-label={props.option.label}
      placeholder={props.option.placeholder}
      options={props.option.choices}
      onChange={handleDropdownChange}
      selectedIconPosition={
        props.notifyWhenInStockInDropdown
          ? IconPosition.LEFT
          : IconPosition.RIGHT
      }
      hasMarginBottom={choicesWithColour.length > 0 || props.index >= 0}
      selected={props.selectedOptions?.[props.option.key]}
      maxDropdownHeight={props.maxDropdownHeight || '146px'}
      width={props.dropdownWidth}
      {...(props.option.label === OptionType.Size && {
        customErrorMessage: props.customErrorMessage,
      })}
    />
  )
}

const SwatchComponent = (props) => {
  const { choicesWithColour } = props
  const handleColourChange = (value) => {
    props.onOptionChange(props.option.key, value)
    props.onColourSwatchClick?.()
  }
  const theme = useTheme()

  if (
    choicesWithColour.length > 0 &&
    !props.forceDropDowns &&
    (props.swatch?.show ?? true)
  ) {
    return (
      <Swatch
        shape={props.swatchShape ?? SwatchShape.SQUARE}
        colours={choicesWithColour}
        selectedColour={props.selectedOptions?.[props.option.key]}
        onColourChange={handleColourChange}
        i18nText={props.i18nText.swatch}
        showOnlyTwoRows={props.showOnlyTwoRows}
        displayColoursAsTextOnly={props.displayColoursAsTextOnly}
        size={theme.patterns.productBlock.components.swatch.productOptionSize}
        noSwatchBorder={theme.patterns.productBlock.components.swatch.noBorder}
      />
    )
  }
  return null
}

const RenderImageSwatch = (props) => {
  const { choicesWithColour } = props
  return (
    <ImageSwatch
      textStyle={props.textStyle}
      label={props.option.localizedKey || props.option.label}
      labelHidden={props.hideDropDownsLabel || false}
      required={false}
      ariaLabel={props.option.label}
      images={choicesWithColour}
      selectedImage={props.selectedOptions?.[props.option.key]}
      onImageChange={(value: string) => {
        props.onOptionChange(props.option.key, value)
        props.onColourSwatchClick?.()
      }}
      i18nText={props.i18nText.imageSwatch}
      hideOptionalText={true}
      displayColoursAsTextOnly={props.displayColoursAsTextOnly}
      showAllImageSwatches={props.showAllImageSwatches}
    />
  )
}

export const Option = (
  props: {
    option: Option
    index: number
    required?: boolean
    labelCustomComponent?: React.ReactNode
    dropdownWidth?: string
    maxDropdownHeight?: string
    customErrorMessage?: {
      displayError?: boolean
      errorMessage?: string
    }
    disableColorDropdown?: boolean
  } & ProductOptionsProps,
) => {
  const choicesWithColour = props.option.choices.filter(
    (choice: Choice) => choice.colour,
  )

  const OptionLabel = (
    <LabelText required={true} textStyle={props.textStyle}>
      {props.option.localizedKey || props.option.label}
    </LabelText>
  )

  switch (props.option.type) {
    case OptionType.Dropdown:
      return (
        <React.Fragment>
          {!props.disableColorDropdown ? (
            <DropdownComponent
              {...props}
              choicesWithColour={choicesWithColour}
            />
          ) : (
            OptionLabel
          )}
          <SwatchComponent {...props} choicesWithColour={choicesWithColour} />
        </React.Fragment>
      )
    case OptionType.Image:
      return (
        <RenderImageSwatch {...props} choicesWithColour={choicesWithColour} />
      )
    case OptionType.Swatch:
      return (
        <React.Fragment>
          {OptionLabel}
          <Swatch
            shape={props.swatchShape ?? SwatchShape.SQUARE}
            colours={choicesWithColour}
            selectedColour={props.selectedOptions?.[props.option.key]}
            onColourChange={(value: string) => {
              props.onOptionChange(props.option.key, value)
              props.onColourSwatchClick?.()
            }}
            i18nText={props.i18nText.swatch}
            showOnlyTwoRows={props.showOnlyTwoRows}
            displayColoursAsTextOnly={props.displayColoursAsTextOnly}
          />
        </React.Fragment>
      )
    default:
      return (
        <RadioGroup
          required={props.required ?? true}
          label={props.option.localizedKey || props.option.label}
          textStyle={props.textStyle}
          selectedValue={props.selectedOptions?.[props.option.key]}
          labelHidden={false}
          // @TODO REBUILD-6279 improve how this is sent and split size guide container
          labelCustomComponent={
            props.option.attribute ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                {props.labelCustomComponent}
              </div>
            ) : null
          }
          columns={2}
          getState={(value: string) =>
            props.onOptionChange(props.option.key, value)
          }
          horizontal={true}
          defaultValue={props.selectedOptions?.[props.option.key]}
        >
          {props.option.choices.map((choice: Choice, index: number) => (
            <SquaredRadioInput
              name={props.option.key}
              title={choice.title}
              value={choice.key}
              key={index}
              disabled={choice.disabled}
              useDisabledStyling={choice.useDisabledStyling}
              borderWidth="2px"
            />
          ))}
        </RadioGroup>
      )
  }
}
